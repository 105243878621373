@use "reset";
@use "colors";
@use "dsfr-fix";
@use "src/components/Map/housing-popup-overrides";
@use "notification";

:root[data-fr-scrolling] body {
  overflow: auto;
}

:root {
  --material-transition-curve: cubic-bezier(.39,.4,.82,.78);
}

.zlv-container {
  min-height: 600px;
}

.fr-table {

  th {
    white-space: nowrap;
  }

  &.with-select {
    th:first-child {
      width: 48px;
    }

    input[type='checkbox'] + label:before {
      margin-top: 0 !important;
    }

    &.with-row-number {
      th:nth-child(2),
      tr > td:nth-child(2) {
        width: 42px;
        padding-left: 8px;
        padding-right: 0;
      }
    }
  }

  &:not(.with-select) {
    &.with-row-number {
      th:first-child,
      tr > td:first-child {
        width: 42px;
      }
    }
  }

  &.with-view {
    th:last-child,
    tr > td:last-child {
      width: 100px;
      padding: 0;
    }
  }

  &.with-modify-last {
    th:last-child,
    tr > td:last-child {
      width: 130px;
      padding: 0;
    }
  }

  &.campaign-list {
    th:last-child,
    tr > td:last-child {
      width: 220px;
      padding: 0;
    }
  }
}

.zlv-table {
  table:before {
    display: none !important;
  }
}

.d-inline-block {
  display: inline-block !important;
}

.d-none,
.no-head thead {
  display: none !important;
}

.d-sm-block {
  @media (min-width: 576px) {
    display: block !important;
  }
}

.d-md-block {
  @media (min-width: 768px) {
    display: block !important;
  }
}

.d-lg-block {
  @media (min-width: 992px) {
    display: block !important;
  }
}

.d-lg-none {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.py-xs-0.fr-py-7w, .py-xs-0.fr-py-6w, .py-xs-0.fr-py-11w {
  @media (max-width: 575px) {
    padding: 0 !important;
  }
}


.py-xs-3w.fr-py-7w, .py-xs-3w.fr-py-6w, .py-xs-3w.fr-py-11w {
  @media (max-width: 575px) {
    padding: 1.5rem !important;
  }
}

.bg-white {
  background-color: var(--white-1000) !important;
}

.bg-100 {
  background-color: var(--grey-975) !important;
}

.bg-900 {
  background-color: var(--grey-900) !important;
}

.bg-925 {
  background-color: var(--grey-925) !important;
}

.bg-950 {
  background-color: var(--grey-950) !important;
}

.bg-975 {
  background-color: var(--grey-975) !important;
}

.bg-bf113 {
  background-color: var(--blue-france-113);
}

.bg-bf925 {
  background-color: var(--blue-france-925) !important;
}

.bg-bf950 {
  background-color: var(--blue-france-950) !important;
}

.bg-bf975 {
  background-color: var(--blue-france-975);
}

.bg-blue-cumulus-925 {
  background-color: var(--blue-cumulus-925);
}

.color-grey-50 {
  color: var(--grey-50);
}

.color-grey-625 {
  color: var(--grey-625-425);
}

.color-bf925-active {
  color: var(--blue-france-925-active);
}

.color-bf525 {
  color: var(--blue-france-main-525) !important;
}

.color-bf113 {
  color: var(--blue-france-113) !important;
}

.color-grey-850 {
  color: var(--grey-850);
}

.color-red-marianne-625 {
  color: var(--red-marianne-625);
}

.color-black-50 {
  color: var(--black-50) !important;
}

.color-info-425 {
  color: var(--info-425) ;
}

.border-bf-925-active {
  border: 1px solid var(--blue-france-925-active);
}

.capitalize {
  text-transform: capitalize;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block !important;
}

.float-right {
  float: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.justify-space-between {
  justify-content: space-between;
}

.valign-top {
  vertical-align: top;
}

.bordered {
  border: 1px solid var(--grey-925);
}

.bordered-b {
  border-bottom: 1px solid var(--grey-925);
}

.fr-tag-click {
  color: var(--blue-france-113) !important;
  background-color: #e5e5f4 !important;
  margin-right: 8px;
  margin-bottom: 4px;

  button {
    background-color: #e5e5f4 !important;
  }
}

button.fr-select {
  text-align: left;
}

.statusTabs {

  .fr-tabs__tab {
    font-size: .875rem!important;
  }

  li:last-child {
    .fr-tabs__tab:not([aria-selected='true']) {
      background-color: var(--grey-925);
    }
  }
}

.select-multi-input, .select-single-input {
  position: relative;

  .select-multi-options, .select-single-options {
    background-color: var(--white-1000);
    box-shadow: 0 0 10px var(--grey-925);
    position: absolute;
    z-index: 10;
    width: 100%;
    display: none;
    padding: 0.5rem 1rem;
    white-space: pre-wrap;

    &.select-multi-options__visible {
      display: inherit;
      max-height: 500px;
      overflow: auto;
    }

    &.select-single-options__visible {
      display: block;
      position: sticky;
      padding: 0;
    }

    .fr-checkbox-group:first-child {
      margin-top: 0;
    }
  }
}

.status-label {
  border-radius: 3px;
  text-transform: uppercase;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 20px;
  width: fit-content;
  font-weight: 700;
  color: rgba(102, 103, 61, 1);
  text-wrap: none;
  vertical-align: middle;

  & + div.status-label {
    margin-top: 8px;
  }

  & + span.status-label {
    margin-left: 8px;
  }
}

a.fr-btn[target='_blank']:after {
  content: none;
}

.subtitle {
  color: var(--grey-425);
}

.app-card-xs {
  width: fit-content;
  display: inline-block !important;

  .fr-card__content {
    padding: 0.5rem 1rem !important;
  }

  .fr-card__desc {
    margin: 0 !important;
    padding: 0 !important;
  }

  & + .app-card-xs {
    margin-left: 0.5rem;
  }

  .card-icon {
    margin-bottom: 0.25rem;
    line-height: 1rem;
    font-size: 1rem;
  }
}

.icon-xs {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-975);
  color: var(--grey-625);
  border-radius: 50%;
  padding: 0.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.card-title-icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--grey-625);
  background-color: var(--grey-975);
  border-radius: 50%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 3rem;
  height: 3rem;
}

.zlv-label {
  margin-bottom: 0 !important;
  color: var(--grey-425);
  font-weight: 500;
  font-size: 14px;
}

.zlv-label-icon {
  margin-bottom: 0 !important;
  color: var(--grey-425);
  font-weight: 700;
  font-size: 14px;
  margin-top: 0.5rem;
}

.zlv-label-icon .fr-icon-xs {
  margin-right: 0.2rem;
  font-size: 1.25rem !important;
}

@for $i from 1 through 9 {
  .weight-#{$i}00 {
    font-weight: $i * 100;
  }
}

.grow-container {
  flex-grow: 1;

  & > * {
    height: 100%;
  }
}

.mailto {
  color: var(--blue-france-main-525);
}

.pre-wrap {
  white-space: pre-wrap;
}

.h-fit-content {
  height: fit-content !important;
}

.italic {
  font-style: italic;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-align-center {
  align-items: center
}

.flex-1 {
  flex: 1;
}

.word-wrap {
  hyphens: auto;
  word-wrap: break-word;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.modal-lg {

  .fr-col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }

}

li.error {
  section.fr-accordion {
    border: 2px solid var(--error-425);

    &:before {
      display: none !important;
    }
  }
  h3 {
    button {
      color: var(--error-425) !important;
    }
  }
}

.no-border {
  box-shadow: none !important;
  &:before{
    box-shadow: inset 0 1px 0 0 var(--border-default-grey) !important;
  }
}

.first-tab-grey {

  ul {
    li:first-of-type > button.fr-tabs__tab {
      background-color: var(--grey-975) !important;
    }
  }
}


.fr-header__body {
  a:before {
    display: none !important;
  }

  .fr-header__service-tagline {
    label {
      display: none;
    }

    input {
      width: 450px;
    }

  }
}

.radio-button-list {

  .fr-radio-group {
    max-width: 100%
  }

  .fr-radio-group::after{
    content: '';
    position: absolute;
    background-color: var(--grey-925);
    bottom: 0;
    left: -13px;
    right: -4px;
    height: 1px;
  }
}

button.no-sort::after {
  color: var(--grey-main-525);
}

.disabled {
  color: var(--text-disabled-grey);
}
