.card {
  border: 1px solid var(--border-default-grey);
  padding: 1rem;
  width: 100%;
}

.fill {
  border: none;
  background-color: var(--background-alt-grey)
}

.subCard {
  width: 100%;
  height: fit-content !important;

  + .subCard {
    margin-left: 0;
    margin-top: 1rem;
    display: block;
  }
}

.title {
  font-size: 1rem !important;
}

.label {
  margin-bottom: 0.25rem;
  color: var(--grey-625);
  font-weight: 500;
}

.reference {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.link {
  font-weight: normal;
  display: inline-flex;
  align-items: center;
}

.titleInline {
  display: flex;
}
