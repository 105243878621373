.actions {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.action {
  width: 100% !important;
  justify-content: center;
}
