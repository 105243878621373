.count {
  display: flex;
  flex-flow: column;
}

.inline {
  flex-flow: row;
}

.container {
  display: flex;
  align-items: center;
}

.title {
  display: inline-flex;
  margin: 0 0.5rem 0 0;
}
