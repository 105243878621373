.tile {
  border: 1px solid var(--grey-925) !important;
  box-shadow: none !important;
  display: block !important;
}

.tileBody {
  text-align: left !important;
  align-items: flex-start !important;
  padding-top: 0 !important;
}

.tileIcon {
  margin-left: 1.5rem;
  margin-top: 1.5rem;
}

.iconResource {
  background-color: var(--green-menthe-975);
  color: var(--green-menthe-main-548);
}

.iconCommunity {
  background-color: var(--blue-ecume-950);
  color: var(--blue-ecume-sun-247-hover);
}

.iconHelp {
  background-color: var(--green-bourgeon-975);
  color: var(--green-bourgeon-sun-425);
}

.iconAgenda {
  background-color: var(--pink-macaron-975);
  color: var(--pink-macaron-sun-406-moon-833-hover);
}

.iconStep {
  color: var(--grey-425);
}
