.separator {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--grey-850);
  line-height: 0.1em;
  margin: 1rem 0 2rem;
  padding-top: 1rem;

  span {
    background: var(--white-1000);
    padding: 0 1rem;
  }
}
