.buttonLink {
  background-image: var(--underline-img),var(--underline-img);
  background-position: var(--underline-x) 100%,var(--underline-x) calc(100% - 0.0625em);
  background-repeat: no-repeat,no-repeat;
  background-size: var(--underline-hover-width) 0.125em,var(--underline-idle-width) 0.0625em;
  transition: background-size 0s;

  --hover-tint: transparent;
  --active-tint: transparent;

  @media (hover: hover) and (pointer: fine) {
    &:hover, &:active {
      --underline-hover-width: var(--underline-max-width);
    }
  }
}

.buttonLinkSm {
  font-size: 0.875rem;
}

.colorInherit {
  color: inherit;
}
