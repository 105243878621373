.coOwnerCard {
  width: 100% !important;
  margin-left: 0 !important;
}

.housingBouton {
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.removeIcon {
  a::after {
    display: none !important;
  }
}
