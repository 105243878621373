.selectableListHeader {
  padding: 0.5rem 0.5rem 0.5rem 1rem !important;
  margin: 1rem 0 !important;
}

.selectableListHeaderInfo {
  background-color: var(--blue-cumulus-925);
}

.selection {
  margin-right: 1rem;
  font-weight: 500;
}

.unselect {
  padding-bottom: 0.2rem;
  background-image: var(--underline-img), var(--underline-img);
  background-position: var(--underline-x) 100%,
    var(--underline-x) calc(100% - 0.0625em);
  background-repeat: no-repeat, no-repeat;
  background-size: var(--underline-hover-width) 0.125em,
    var(--underline-idle-width) 0.0625em;
  transition: background-size 0s;

  &:hover {
    --underline-hover-width: var(--underline-max-width);
    background-color: initial !important;
  }
}

.col {
  flex: 1 0 auto !important;
}
