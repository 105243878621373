.dpe {
  display: inline-block;
  color: #1e1e1e;
  clip-path: polygon(0% 0%, 80% 0, 100% 50%, 80% 100%, 0% 100%);
  width: 2.5rem;
  height: 1.5rem;
  text-align: center;
  padding-right: 0.5rem;
  line-height: inherit;
  font-weight: 700;
}

.A {
  background-color: #519740;
}

.B {
  background-color: #67C84D;
}

.C {
  background-color: #D6FC5E;
}

.D {
  background-color: #FFFD54;
}

.E {
  background-color: #F7CD46;
}

.F {
  background-color: #F19E4B;
}

.G {
  background-color: #EA3223;
  color: #fff;
}
