.article {
  border: 1px solid var(--light-border-default-grey, #ddd);
  padding: 1rem;
}

.row {
  margin-bottom: 0.75rem;
}

.logoViewer {
  padding: 0;
  display: flex;
  align-items: flex-start;

  img {
    max-width: 120px;
    max-height: 260px;
    display: block;
  }

  button {
    margin-left: 12px;
  }
}

.info {
  position: relative;
  padding-left: 20px;
}

.info::before {
  position: absolute;
  top: 1px;
  left: 0;
}
