.link {
  & > a {
    background: none;
    color: var(--grey-425);
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}

.brandLink {
  &::after {
    content: none;
  }
}

.partners {
  justify-content: flex-start;
}
