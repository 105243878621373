.searchContainer {
  position: relative;
  flex-grow: 1;

  .searchResults {
    background-color: var(--grey-950);
    position: absolute;
    top: 40px;
    z-index: 10;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    font-size: 1rem;

    div {
      color: var(--black-50);
      cursor: pointer;
      padding: 0.3rem;

      &:hover {
        background-color: var(--grey-975);
      }
    }
  }

  .searchResultsLg {
    top: 56px;
  }
}
