.buttonInGroup {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    margin-bottom: 0 !important;
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

.right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.tabs {
  box-shadow: none;

  &::before {
    box-shadow: inset 0 1px 0 0 var(--border-default-grey) !important;
  }
}

.panel {
  padding: 0;
}

.steps {
  background-color: var(--background-alt-blue-france);
}


.lastStep {
  margin-top: -1.2rem;
}
