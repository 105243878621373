.drawerContent {
  opacity: 0;
  transition: opacity 0s;
}

.drawerContentExpanded {
  opacity: 1;
  transition: opacity 0.4s var(--material-transition-curve);
}

.selected {
  // Hack to avoid transparency issues with the selected item
  background-color: #ebebf6 !important;

  &:hover {
    background-color: #e0e0f2 !important;
  }
}

.icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.5rem;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
}

.checkboxLabel {
  label {
    font-weight: bold;

    span{
      font-weight: normal;
    }
  }
}

.asAccordionExpanded {

  h3 {
    background-color: var(--background-open-blue-france);
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.75rem 1rem;
    margin: 0;
    color: var(--text-action-high-blue-france);
  }
}
