.event {
  background-color: var(--grey-975);
  padding: 0.75rem 1.25rem;
}

.eventData {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .eventDate {
    color: var(--grey-425);
  }

  .eventCreator {
    color: var(--blue-france-main-525);
  }
}

.eventContentRowContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.eventContentColContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.eventContent {
  background-color: var(--white-1000);
  padding: 0.35rem 0.75rem;
  margin-bottom: 0.4rem;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  min-height: 2rem;
  width: 100%;
}
