/*!* ------------------------------------ *\*/
/*  ICONS*/
/*\* ------------------------------------ *!*/

[class^="fr-icon-"].icon-left {
    margin-right: .5rem;
}

[class^="fr-icon-"].icon-right {
    margin-left: .5rem;
}
