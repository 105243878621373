.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  background-color: var(--background-alt-grey);

  button:not(:last-child) {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__right {
    justify-self: flex-end;
  }
}
