.container {
  display: flex;
  flex-flow: column nowrap;
}

.divider {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--light-border-plain-grey, #3a3a3a);

  &::before, &::after {
    content: '';
    height: 1px;
    background-color: var(--light-border-plain-grey, #3a3a3a);
    flex-grow: 1;
    margin: 0 1rem;
  }
}

.center {
  align-self: center;
}
