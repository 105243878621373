.editor {
  min-height: 300px;
  max-height: none !important;
  overflow: auto;

  &__text {
    &--bold {
      font-weight: bold;
    }

    &--italic {
      font-style: italic;
    }

    &--underline {
      text-decoration: underline;
    }
  }
}
